<template>
  <div class="list">
    <list-search @onSearch="onSearch" @clearSearch="clearSearch" :childData="childData"></list-search>
    <div class="contractMenu">
        <list-menu @buttonClick="buttonClick" :menuData="menudata" ></list-menu>
    </div>
    <purchase-plan-table :tableData="tableData"></purchase-plan-table>
    <div class="xbjtablepage" v-if="tableData&&tableData.length>0">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size = "pageSize"
        layout="total, prev, pager, next, jumper"
        :total = "total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { SearchList, SearchData, tableField, tableData, menudata } from './js/setting.js'
import { requestForm, exportForm } from '@/assets/js/http.js'
import { ElLoading } from 'element-plus'
// import { MIME } from '@/assets/js/mime.js'
import dayjs from 'dayjs'
export default {
  components: Component.components,
  name: 'PurchasePlan',
  data () {
    var pageSize = parseInt((document.documentElement.clientHeight - 330) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      pageNum: 1, // 当前第几页
      pageSize: pageSize, // 每页显示多少条
      total: 0, // 共多少条
      tableField: tableField,
      menudata: menudata,
      tableData: tableData,
      childData: { searchList: SearchList, searchData: SearchData }
    }
  },
  created () {
    this.myBidData()
  },

  methods: {
    tableAction: function (index, data, clickBtn) {
      if (clickBtn.operationButton === 'see') {
        this.$router.push({ params: { viewName: 'CreatRepair' } })
      } else {
        this.$router.push({ params: { viewName: 'CreatRepair' }, query: { id: data.id } })
        this.$emit('OpenView', { openView: 'CreatRepair' })
      }
    },
    // 获取采购-交付计划数据列表
    myBidData () {
      let xqksTime = ''
      let xqjsTime = ''
      if (this.childData.searchData.demandDate) {
        xqksTime = dayjs(this.childData.searchData.demandDate[0]).format('YYYY-MM-DD HH:mm:ss')
        xqjsTime = dayjs(this.childData.searchData.demandDate[1]).format('YYYY-MM-DD 23:59:59')
      }
      let jfksTime = ''
      let jfjsTime = ''
      if (this.childData.searchData.actualDeliveryDate) {
        jfksTime = dayjs(this.childData.searchData.actualDeliveryDate[0]).format('YYYY-MM-DD HH:mm:ss')
        jfjsTime = dayjs(this.childData.searchData.actualDeliveryDate[1]).format('YYYY-MM-DD 23:59:59')
      }
      const obj = {
        itemNumber: this.childData.searchData.itemNumber,
        materialDescription: this.childData.searchData.materialDescription,
        supplierName: this.childData.searchData.supplierName,
        bednr: this.childData.searchData.bednr,
        XQStrStartDate: xqksTime,
        XQStrEndDate: xqjsTime,
        JFStrStartDate: jfksTime,
        JFStrEndDate: jfjsTime,
        purchaseOrderNumber: this.childData.searchData.purchaseOrderNumber,
        buyer: this.childData.searchData.buyer,
        lgort:this.childData.searchData.lgort
      }
      requestForm('/api/deliver/deliver/queryAll?' + 'pageNum=' + this.pageNum + '&' + 'pageSize=' + this.pageSize, 'POST', obj).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.records
          this.total = res.data.total
        }
      })
    },
    buttonClick () {
      let xqksTime = null
      let xqjsTime = null
      if (this.childData.searchData.demandDate) {
        xqksTime = dayjs(this.childData.searchData.demandDate[0]).format('YYYY-MM-DD HH:mm:ss')
        xqjsTime = dayjs(this.childData.searchData.demandDate[1]).format('YYYY-MM-DD 23:59:59')
      }
      let jfksTime = null
      let jfjsTime = null
      if (this.childData.searchData.actualDeliveryDate) {
        jfksTime = dayjs(this.childData.searchData.actualDeliveryDate[0]).format('YYYY-MM-DD HH:mm:ss')
        jfjsTime = dayjs(this.childData.searchData.actualDeliveryDate[1]).format('YYYY-MM-DD 23:59:59')
      }
      const obj = {
        itemNumber: this.childData.searchData.itemNumber,
        materialDescription: this.childData.searchData.materialDescription,
        supplierName: this.childData.searchData.supplierName,
        XQStrStartDate: xqksTime,
        XQStrEndDate: xqjsTime,
        JFStrStartDate: jfksTime,
        JFStrEndDate: jfjsTime,
        purchaseOrderNumber: this.childData.searchData.purchaseOrderNumber,
        lgort:this.childData.searchData.lgort,
        buyer:this.childData.searchData.buyer,
        bednr:this.childData.searchData.bednr,
      }
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.2)'
      })
      exportForm('/api/deliver/deliver/poiDeliver', 'post', obj).then(
        (response) => {
          try {
            const url = window.URL.createObjectURL(response.data)
            const eleLink = document.createElement('a')
            eleLink.href = url
            eleLink.click()
            window.URL.revokeObjectURL(url)
          } catch (error) {
            console.error('download function error!', error)
          }
          loading.close()
        })
    },
    // 查询
    onSearch (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] === 'All' || data[key] === '') {
          delete data[key]
        }
      })
      this.pageNum = 1
      this.myBidData()
    },
    // 清空
    clearSearch (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] === 'All' || data[key] === '') {
          delete data[key]
        }
      })
      this.pageNum = 1
      this.myBidData()
    },
    // 每页显示多少条
    handleSizeChange (val) {
      this.pageSize = val
      this.myBidData()
    },
    // 当前第几页
    handleCurrentChange (val) {
      this.pageNum = val
      this.myBidData()
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
  .xbjtablepage{
    padding: 18px 0;
    text-align: center;
  }
  .contractMenu{
    float: right;
}
</style>
