export var SearchList = [
  { labe: '物料编号', code: 'itemNumber', type: 'input' },
  { labe: '物料描述', code: 'materialDescription', type: 'input' },
  { labe: '供应商', code: 'supplierName', type: 'input', placeholder: '编码/名称' },
  { labe: '采购单编号', code: 'purchaseOrderNumber', type: 'input' },
  {
    labe: '需求日期',
    code: 'demandDate',
    type: 'daterange'
  },
  {
    labe: '实际交付日期',
    code: 'actualDeliveryDate',
    type: 'daterange'
  },
  { labe: '采购员', code: 'buyer', type: 'input', placeholder: '采购员编码或名称' },
  {
    labe: '需求类型',
    code: 'bednr',
    type: 'input',
    placeholder: '类型'
  },
  {
    labe: 'BU',
    code: 'lgort',
    type: 'input',
    placeholder: 'BU'
  },
]
export var SearchData = {
  itemNumber: '',
  materialDescription: '',
  supplierName: '',
  demandDate: '',
  actualDeliveryDate: '',
  purchaseOrderNumber: '',
  bednr: ''
}

export var tableField = [
  { label: '物料编号', code: 'wlCode', type: 'input', width: '' },
  { label: '供应商名称', code: 'gysName', type: 'input', width: '' },
  { label: '需求数量/单位', code: 'xqNumDw', type: 'input', width: '' },
  { label: '需求日期', code: 'xqTime', type: 'input', width: '' },
  { label: '交付数量/单位', code: 'jfNumDw', type: 'input', width: '' },
  { label: '交付日期', code: 'jfTime', type: 'input', width: '' },
  {
    label: '操作',
    code: 'act',
    width: '200',
    type: 'action',
    actionData: [
      {
        actionLabel: '编辑',
        id: '10'
      },
      {
        actionLabel: '保存',
        id: '11'
      }
    ]
  }
]

export var tableData = [
]

export var menudata = [{
  label: '导出',
  action: 'draftContract',
  id: 1
}
]
